<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de tarifa"
                    description="Complete el formulario y cree un nueva tarifa"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-select 
                        id="grupoProcedimiento"
                        label="Grupo"
                        v-model="tarifa.idGrupoProcedimiento"
                        :errors="validate$.idGrupoProcedimiento.$errors" 
                        :data="grupoProcedimientos"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                        @change="() => {
                            tarifa.idTipoProcedimiento = null;
                            getTipoProcedimientoByIdGrupo(tarifa.idGrupoProcedimiento);
                        }"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-select 
                            id="tipoServicios"
                            label="Servicios"
                            v-model="tarifa.idTipoProcedimiento"
                            :errors="validate$.idTipoProcedimiento.$errors" 
                            :data="tipoProcedimientos"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="preciobase"
                    placeholder="Precio Privado"
                    label="Precio Privado"
                    type="number"
                    v-model="tarifa.precioPrivado"
                />
            </div>

            <div class="col-12">
                <paginate-table
                    :headers="[
                                'Cliente', 
                                'Precio Base', 
                                '%']"
                    :is-empty="tarifa.detalles.length"
                    empty-message="No hay de servicios para mostrar">
                    
                    <template v-slot:tr>
                        <tr v-for="cliente in tarifa.detalles" :key="cliente.id">
                            <th scope="row">{{ cliente.nombre }}</th>
                            <td>
                                <custom-input
                                    id="preciobase"
                                    placeholder="Precio Base"
                                    type="number"
                                    v-model="cliente.precioBase"
                                />
                            </td>
                            <td>
                                <custom-input
                                    id="cobertura"
                                    placeholder="%"
                                    type="number"
                                    v-model="cliente.cobertura"
                                />
                            </td>
                        </tr>
                    </template>
                </paginate-table>
            <div>
            </div>
            </div>
    </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListTarifa',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateTarifa',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import PaginateTable from '@/components/table/PaginateTable.vue'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'
import { useGrupoProcedimiento } from '@/composables/useGrupoProcedimiento'
import { useTarifa } from '@/composables/useTarifa'

const { grupoProcedimientos, getGrupoProcedimientos } = useGrupoProcedimiento();

const {
    tipoProcedimientos,
    getTipoProcedimientoByIdGrupo
} = useTipoProcedimiento();

const {
    validate$,
    tarifa,
    isLoading,
    onCreateHandle,
    getDetalles
} = useTarifa();

// id: null,
//         idGrupoProcedimiento: null,
//         idTipoProcedimiento: null,
//         precioPrivado: null,
//         detalles: []

onMounted(async () => {
    await getGrupoProcedimientos();
    await getDetalles();
})
</script>