<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de servicios"
                    description="Complete el formulario y cree un nuevo servicios"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-select 
                        id="grupoProcedimiento"
                        label="Tipo Servicio"
                        v-model="tipoProcedimiento.idGrupoProcedimiento"
                        :errors="validate$.idGrupoProcedimiento.$errors" 
                        :data="grupoProcedimientos"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="tipoProcedimiento.nombre"
                    :errors="validate$.nombre.$errors" 
                />
            </div>
<!--             
            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="descripcion"
                    placeholder="Descripcion"
                    label="Descripcion"
                    type="text"
                    v-model="tipoProcedimiento.descripcion"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="preciobase"
                    placeholder="Precio Base"
                    label="Precio Base"
                    type="number"
                    v-model="tipoProcedimiento.precioBase"
                />
            </div> -->
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListTipoProcedimiento',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateTipoProcedimiento',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'
import { useGrupoProcedimiento } from '@/composables/useGrupoProcedimiento'

const { grupoProcedimientos, getGrupoProcedimientos } = useGrupoProcedimiento();

const {
    validate$,
    isLoading,
    tipoProcedimiento,
    onCreateHandle
} = useTipoProcedimiento();

onMounted(async () => {
    await getGrupoProcedimientos();
})
</script>