<template>
    <default-layout>
        <section class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de reclamos"
                    description="Complete el formulario y cree un nuevo reclamo"
                    icon="fas fa-user-injured">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            @click="onCreateHandle"
                            :disabled="true"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-12 col-lg-12">
                <div class="w-10">
                    <custom-input
                                id="caso"
                                placeholder="No. Caso"
                                label="No. Caso"
                                type="text"
                                v-model="procedimiento.id"
                    />
                </div>
            </div>

            <section class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                <custom-input
                            id="caso"
                            placeholder="Codigo"
                            label="Codigo"
                            v-model.number="procedimiento.idMedico"
                />
            </section>

            <section class="col-12 col-sm-12 col-md-10 col-xl-10 col-lg-10">
                <custom-select 
                        id="medico"
                        label="Médicos"
                        placeholder="Seleccione un médico"
                        v-model="procedimiento.idMedico"
                        :errors="validate$.idMedico.$errors" 
                        :data="medicos"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"/>
            </section>

            <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                <custom-input
                        id="nss"
                        label="NSS"
                        type="text"
                        placeholder="NSS"
                        v-model="procedimiento.paciente.nss"
                        :errors="validate$.paciente.nss.$errors"
                        @change="getPacienteByNss()"
                />
            </div>
            
            <div class="col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5">
                <custom-select 
                        id="cliente"
                        label="ARS"
                        placeholder="Seleccione un cliente"
                        v-model="procedimiento.paciente.idCliente"
                        :errors="validate$.paciente.idCliente.$errors" 
                        :data="clientes"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5">
                <custom-select 
                        id="cliente"
                        label="Destino"
                        placeholder="Seleccione un destino"
                        v-model="procedimiento.idDestino" 
                        :data="clientes"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                />
            </div>

           
            <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                <custom-select 
                        id="tipoIdentificacion"
                        label="Tipo identificación"
                        v-model="procedimiento.paciente.idTipoIdentificacion"
                        :errors="validate$.paciente.idTipoIdentificacion.$errors" 
                        :data="tipoIdentificaciones"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="identificacion"
                        label="Identificación"
                        type="text"
                        placeholder="Identificación"
                        v-model="procedimiento.paciente.identificacion"
                        :errors="validate$.paciente.identificacion.$errors"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                            id="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            type="text"
                            v-model="procedimiento.paciente.nombre"
                            :errors="validate$.paciente.nombre.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-calendar
                        id="fechaNacimiento"
                        placeholder="Fecha Nacimiento"
                        label="Fecha Nacimiento"
                        v-model="procedimiento.paciente.fechaNacimiento"
                        :errors="validate$.paciente.fechaNacimiento.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                <custom-select 
                        id="sexo"
                        label="Sexo"
                        v-model="procedimiento.paciente.sexo"
                        :errors="validate$.paciente.sexo.$errors" 
                        :data="[{
                                id: 'F',
                                name: 'Femenino'
                            },
                            {
                                id: 'M',
                                name: 'Masculino'
                        }]"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.name"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="email"
                        placeholder="Email"
                        label="Email"
                        type="email"
                        v-model="procedimiento.paciente.email"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                            id="telefono"
                            placeholder="Teléfono"
                            label="Teléfono"
                            type="tel"
                            v-model="procedimiento.paciente.telefono"
                            :errors="validate$.paciente.telefono.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                        id="direccion"
                        placeholder="Dirección"
                        label="Dirección"
                        type="text"
                        v-model="procedimiento.paciente.direccion" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                            id="diagnostico"
                            placeholder="Diagnóstico"
                            label="Diagnóstico"
                            type="text"
                            v-model="procedimiento.descripcion"
                />
            </div>

            <div class="col-12 col-md-12 col-xl-12 col-lg-12">
                <section-title 
                    title="Procedimientos"
                    description="Complete el formulario y cree un nuevo cliente"
                    icon="far fa-address-book" 
                />

                <div>
                    <section>
                        <!-- <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                            <div class="px-4 py-3 header-line">
                                <form @submit.stop.prevent="addDetalleHandle">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-select 
                                                id="grupoProcedimiento"
                                                label="Tipo Servicio"
                                                v-model="procedimientoDetalle.grupoProcedimiento"
                                                :errors="vDetalle$.grupoProcedimiento.$errors" 
                                                :data="grupoProcedimientos"
                                                :set-code="(model) => model"
                                                :set-label="(model) => model.nombre"
                                                @change="() => {
                                                    procedimientoDetalle.precio = null;
                                                    procedimientoDetalle.tipoProcedimiento = null;
                                                    getTipoProcedimientoByIdGrupo(procedimientoDetalle.grupoProcedimiento?.id);
                                                }"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-select 
                                                    id="tipoServicios"
                                                    label="Servicios"
                                                    v-model="procedimientoDetalle.tipoProcedimiento"
                                                    :errors="vDetalle$.tipoProcedimiento.$errors" 
                                                    :data="tipoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.nombre"
                                                    @change="() => {
                                                        procedimientoDetalle.precio = null;
                                                        changePrice(procedimientoDetalle.tipoProcedimiento?.precioBase);
                                                    }"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                    id="cantidad"
                                                    placeholder="Cantidad"
                                                    label="Cantidad"
                                                    type="number"
                                                    v-model="procedimientoDetalle.cantidad"
                                                    :errors="vDetalle$.cantidad.$errors" 
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-input
                                                        id="precio"
                                                        placeholder="Precio"
                                                        label="Precio"
                                                        type="number"
                                                        v-model="procedimientoDetalle.precio"
                                                        :errors="vDetalle$.precio.$errors" 
                                            />
                                        </div>
                                                    
                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="cobertura"
                                                        placeholder="%"
                                                        label="%"
                                                        type="number"
                                                        v-model="procedimientoDetalle.ptcCobertura" 
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="cobertura"
                                                        placeholder="Cobert."
                                                        label="Cobert."
                                                        type="number"
                                                        v-model="procedimientoDetalle.ptcCobertura" 
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="descuento"
                                                        placeholder="Descuento"
                                                        label="Descuento"
                                                        type="number"
                                                        v-model="procedimientoDetalle.descuento"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="autorizacion"
                                                        placeholder="Diferencia"
                                                        label="Diferencia"
                                                        type="text"
                                                        v-model="procedimientoDetalle.noAutorizacion"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="autorizacion"
                                                        placeholder="Autorizacion"
                                                        label="Autorizacion"
                                                        type="text"
                                                        v-model="procedimientoDetalle.noAutorizacion"
                                            />
                                        </div>

                                        <div class="d-none">
                                            <button type="submit"></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> -->

                        <form @submit.stop.prevent="addDetalleHandle">
                            <single-table
                                    :pagination="pagination"
                                    :headers="[
                                            'Grupo', 
                                            'Servicio', 
                                            'Descripción', 
                                            'Cantidad', 
                                            'Precio',
                                            'Total',
                                            'Cobertura',
                                            'Descuento',
                                            'Diferencia',
                                            'Autorización',
                                            ''
                                        ]"
                                    :is-loading="false"
                                    :is-empty="procedimiento.detalles.length"
                                    empty-message="No hay datos procedimientos">
                                                
                                    <template v-slot:tr>
                                        <tr>
                                            <td width="8%">
                                                <custom-select 
                                                    id="grupoProcedimiento"
                                                    v-model="procedimientoDetalle.grupoProcedimiento"
                                                    :errors="vDetalle$.grupoProcedimiento.$errors" 
                                                    :data="grupoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.grupo"
                                                    @change="() => {
                                                        procedimientoDetalle.precio = null;
                                                        procedimientoDetalle.tipoProcedimiento = null;
                                                        getTipoProcedimientoByIdGrupo(procedimientoDetalle.grupoProcedimiento?.id);
                                                    }"
                                                />
                                            </td>
                                            <td width="8%">
                                                <custom-select 
                                                    id="tipoServicios"
                                                    v-model="procedimientoDetalle.tipoProcedimiento"
                                                    :errors="vDetalle$.tipoProcedimiento.$errors" 
                                                    :data="tipoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.id"
                                                />
                                            </td>
                                            <td width="15%">
                                                <custom-input
                                                    id="descripcion"
                                                    placeholder="Descripción"
                                                    type="text"
                                                    :value="procedimientoDetalle.tipoProcedimiento?.nombre"
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                    id="cantidad"
                                                    placeholder="Cantidad"
                                                    type="number"
                                                    v-model="procedimientoDetalle.cantidad"
                                                    :errors="vDetalle$.cantidad.$errors" 
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="precio"
                                                        placeholder="Precio"
                                                        type="number"
                                                        v-model="procedimientoDetalle.precio"
                                                        :errors="vDetalle$.precio.$errors" 
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="total"
                                                        placeholder="Total"
                                                        type="number"
                                                        v-model="procedimientoDetalle.total"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="cobertura"
                                                        placeholder="%"
                                                        type="number"
                                                        v-model="procedimientoDetalle.ptcCobertura" 
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="descuento"
                                                        placeholder="Descuento"
                                                        type="number"
                                                        v-model="procedimientoDetalle.descuento"
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="diferencia"
                                                        placeholder="Diferencia"
                                                        type="text"
                                                        v-model="procedimientoDetalle.diferencia"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="autorizacion"
                                                        placeholder="Autorizacion"
                                                        type="text"
                                                        v-model="procedimientoDetalle.noAutorizacion"
                                                />
                                            </td>
                                            <td></td>
                                        </tr>
                                        <!--  -->
                                        <tr v-for="(proc, index) in procedimiento.detalles" :key="index">
                                            <td width="8%">
                                                <custom-select 
                                                    id="grupoProcedimiento"
                                                    v-model="proc.grupoProcedimiento"
                                                    :errors="vDetalle$.grupoProcedimiento.$errors" 
                                                    :data="grupoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.grupo"
                                                    @change="() => {
                                                        procedimientoDetalle.precio = null;
                                                        procedimientoDetalle.tipoProcedimiento = null;
                                                        getTipoProcedimientoByIdGrupo(procedimientoDetalle.grupoProcedimiento?.id);
                                                    }"
                                                    disabled
                                                />
                                            </td>
                                            <td width="8%">
                                                <custom-select 
                                                    id="tipoServicios"
                                                    v-model="proc.tipoProcedimiento"
                                                    :errors="vDetalle$.tipoProcedimiento.$errors" 
                                                    :data="tipoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.id"
                                                    disabled
                                                />
                                            </td>
                                            <td width="15%">
                                                <custom-input
                                                    id="descripcion"
                                                    placeholder="Descripción"
                                                    type="text"
                                                    :value="proc.tipoProcedimiento?.nombre"
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                    id="cantidad"
                                                    placeholder="Cantidad"
                                                    type="number"
                                                    v-model="proc.cantidad"
                                                    :errors="vDetalle$.cantidad.$errors" 
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="precio"
                                                        placeholder="Precio"
                                                        type="number"
                                                        v-model="proc.precio"
                                                        :errors="vDetalle$.precio.$errors"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="total"
                                                        placeholder="Total"
                                                        type="number"
                                                        v-model="proc.total"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="cobertura"
                                                        placeholder="%"
                                                        type="number"
                                                        v-model="proc.ptcCobertura"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="descuento"
                                                        placeholder="Descuento"
                                                        type="number"
                                                        v-model="proc.descuento"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="diferencia"
                                                        placeholder="Diferencia"
                                                        type="text"
                                                        v-model="proc.diferencia"
                                                        disabled
                                                />
                                            </td>
                                            <td>
                                                <custom-input
                                                        id="autorizacion"
                                                        placeholder="Autorizacion"
                                                        type="text"
                                                        v-model="proc.noAutorizacion"
                                                />
                                            </td>
                                            <td></td>
                                        </tr>
                                        <!-- <tr v-for="(proc, index) in procedimiento.detalles" :key="index">
                                            <td>{{ proc.grupoProcedimiento.nombre }}</td>
                                            <td>{{ proc.tipoProcedimiento.nombre }}</td>
                                            <td>{{ proc.cantidad }}</td>
                                            <td>{{ formatPrice(proc.precio) }}</td>
                                            <td>{{ formatPrice(proc.total) }}</td>
                                            <td>{{ formatPrice(proc.cobertura) }}</td>
                                            <td>{{ formatPrice(proc.descuento) }}</td>
                                            <td>{{ formatPrice(proc.diferencia) }}</td>
                                            <td>{{ proc.noAutorizacion }}</td>
                                            <td>
                                                <button 
                                                        @click="deleteProcedimientoDetalleHandle(proc, index)" 
                                                        type="button" 
                                                        class="btn btn-danger btn-actions ms-2">
                                                        <i class="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr> -->
                                    </template>
                            </single-table>
                            <div class="d-none">
                                <button type="submit"></button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </section>
        
        <custom-float-button 
            :options="[{
                route: 'ListReclamo',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateReclamo',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomCalendar from '@/components/input/CustomCalendar.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import SingleTable from '@/components/table/SingleTable.vue'
// import { usePaciente } from '@/composables/usePaciente'
import { useReclamo } from '@/composables/useReclamo'
import { useCliente } from '@/composables/useCliente'
import { useMedico } from '@/composables/useMedico'
import { useTipoIdentificacion } from '@/composables/useTipoIdentificacion'
import { useGrupoProcedimiento } from '@/composables/useGrupoProcedimiento'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'

const { 
    validate$,
    vDetalle$,
    procedimiento,
    procedimientoDetalle,
    isLoading,
    addDetalleHandle,
    onCreateHandle,
    getPacienteByNss,
    // deleteProcedimientoDetalleHandle,
} = useReclamo();

// const { 
//     formatPrice
// } = usePaciente();

const { clientes, getClientes } = useCliente();
const { tipoIdentificaciones, getTipoIdentificaciones } = useTipoIdentificacion();
const { medicos, getMedicos } = useMedico();
const { tipoProcedimientos, getTipoProcedimientoByIdGrupo } = useTipoProcedimiento();
const { grupoProcedimientos, getGrupoProcedimientos } = useGrupoProcedimiento();

onMounted(async () => {
    await getTipoIdentificaciones();
    await getClientes();
    await getMedicos();
    await getGrupoProcedimientos();
});

watch(() => [procedimientoDetalle.cantidad, 
            procedimientoDetalle.precio, 
            procedimientoDetalle.ptcCobertura,
            procedimientoDetalle.descuento
        ], 
      () => {
            procedimientoDetalle.total =  procedimientoDetalle.cantidad * procedimientoDetalle.precio;
            procedimientoDetalle.diferencia = ((procedimientoDetalle.total - (procedimientoDetalle.ptcCobertura/100) * procedimientoDetalle.total) - procedimientoDetalle.descuento);
});

</script>