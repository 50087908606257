<template>
    <default-layout>
        <section>
            <div>
                <control-section-title 
                    title="Servicios"
                    description="Listado de servicios creados"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            type="button"
                            text="Filtros"
                            class="btn btn-primary"
                            @click="openFilterHandle()"
                            icon="fas fa-filter fa-sm"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div>
                <paginate-table
                    :pagination="pagination"
                    :headers="['#', 
                            'Grupo',
                            'Nombre', 
                            'Fecha Creación']"
                    :is-loading="isLoading"
                    :is-empty="tipoProcedimientos.length"
                    empty-message="No hay de servicios para mostrar"
                    @pageChange="onPaginationHandle($event)">
                    
                    <template v-slot:tr>
                        <tr v-for="tipoProcedimiento in tipoProcedimientos" :key="tipoProcedimiento.id" 
                            @click="route.push({
                                    name: 'UpdateTipoProcedimiento', 
                                    params: { guid: tipoProcedimiento.guid }
                                })" class="cursor-pointer">
                            <th scope="row">{{ tipoProcedimiento.id }}</th>
                            <td>{{ tipoProcedimiento.idGrupoProcedimiento }}</td>
                            <td>{{ tipoProcedimiento.nombre }}</td>
                            <td>{{ formatDate(tipoProcedimiento.createdDate) }}</td>
                        </tr>
                    </template>
                </paginate-table>
            </div>
        </section>

        <custom-float-button 
            :options="[{
                route: 'ListTipoProcedimiento',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateTipoProcedimiento',
                icon: 'fas fa-plus'
            }]"
        />

        <side-nav 
                title="Filters" 
                :is-open="filter.isOpen" 
                @cancel="openFilterHandle">
            
                <form @submit.stop.prevent="getTipoProcedimientos" class="row">
                    <div class="col-12">
                        <custom-input
                            id="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            type="text"
                            v-model="filter.nombre"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaDesde"
                            placeholder="Fecha Desde"
                            label="Fecha Desde"
                            type="date"
                            v-model="filter.dateFrom"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaHasta"
                            placeholder="Fecha Hasta"
                            label="Fecha Hasta"
                            type="date"
                            v-model="filter.dateTo"
                        />
                    </div>

                    <div class="col-12">
                        <custom-button 
                            type="submit"
                            text="Filtrar"
                            class="btn btn-primary float-end"
                            :is-loading="isLoading"
                        />
                    </div>
                </form>
        </side-nav>
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import SideNav from '@/components/SideNav.vue'
import PaginateTable from '@/components/table/PaginateTable.vue'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'

const route = useRouter();

const { 
    tipoProcedimientos,
    filter,
    isLoading,
    pagination,
    getTipoProcedimientos,
    formatDate,
    openFilterHandle,
    onPaginationHandle
} = useTipoProcedimiento();


onMounted(async () => {
    await getTipoProcedimientos();
});
</script>